import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "what-if-they-dont-bounce-back"
    }}>{`What if they don’t ‘bounce back’?`}</h2>
    <p>{`All teens will have ups and downs, especially during the pandemic. Most will bounce back from the downs with time and support. Try using the strategies covered in this module to help support your teen during this time.`}</p>
    <p>{`If your teen is struggling to bounce back, and this is affecting other areas of their lives (e.g. school, social life, family life, activities), it could be a sign of a mental health problem. For some teens, the stress of the pandemic and local restrictions may cause or worsen mental health problems. Some teens might also turn to negative coping strategies, for example alcohol or other drugs, self-harm or unhelpful eating behaviours such as dieting or binge eating.`}</p>
    <p>{`If any of this applies to your teen, seek professional mental health support.`}</p>
    <p>{`For more information, see the modules:`}</p>
    <ul>
      <li parentName="ul">
        <GoTo to="/dashboard" mdxType="GoTo">When things aren’t okay</GoTo>
      </li>
      <li parentName="ul">
        <GoTo to="/dashboard" mdxType="GoTo">Parenting during the pandemic</GoTo>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      